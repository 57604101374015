import axios from "axios";

const REST_API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/customer`;

export const listCustomer = () =>
    axios.get(REST_API_BASE_URL, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });

export const createCustomer = (customer) =>
    axios.post(REST_API_BASE_URL, customer, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });

export const updateCustomer = (customerId, customer) =>
    axios.put(`${REST_API_BASE_URL}/${customerId}`, customer, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });

export const getCustomer = (customerId) =>
    axios.get(`${REST_API_BASE_URL}/${customerId}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });

export const deleteCustomer = (customerId) =>
    axios.delete(`${REST_API_BASE_URL}/${customerId}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });

export const deleteAllCustomers = () =>
    axios.delete(`${REST_API_BASE_URL}/deleteAll`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });

