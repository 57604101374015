import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Card, CardContent, Grid, useTheme, Tabs, Tab } from "@mui/material";
import { getCustomer } from "../../services/CustomerService";
import { getSalesByCustomerId } from "../../services/SaleService"; // Ensure this service method is available
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const CustomerProfile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { customerId } = useParams(); // Retrieve customerId from the URL
  const [customer, setCustomer] = useState(null);
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    // Fetch customer details based on customerId
    getCustomer(customerId)
      .then(response => setCustomer(response.data))
      .catch(error => console.error("Error fetching customer details:", error));
  }, [customerId]);

  
  useEffect(() => {
    const fetchSales = async () => {
        try {
            const response = await getSalesByCustomerId(customerId, 0, 5);
            setSales(response.data);
            console.log(response.data)
        } catch (error) {
            console.error("Error fetching sales data:", error);
        } finally {
            setLoading(false);
        }
    };

    fetchSales();
}, [customerId]);

  if (loading) return <Typography>Loading...</Typography>;

  if (!customer) return <Typography>Loading...</Typography>;


  const columns = [
    { field: 'id', headerName: 'Sales ID', flex: 0.5 },
    { field: 'productName', headerName: 'Product Name', flex: 0.5 },
    { field: 'quantity', headerName: 'Quantity', flex: 0.5 },
    { field: 'totalBalance', headerName: 'Total Balance', flex: 0.5 },
    {
        field: 'date',
        headerName: 'Date',
        flex: 0.5,
    },
    { field: 'paymentStatus', headerName: 'Payment Status', flex: 0.5 },
    { field: 'outstandingBalance', headerName: 'Outstanding Balance', flex: 0.5 },
    { field: 'installmentPlan', headerName: 'Installment Plan', flex: 0.5 },
];


  return (
    <Box m="20px">
            <Header title="CUSTOMER PROFILE" subtitle="View full customer details here" />
            
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: "20px" }}>
        <Tabs
          value={activeTab}
          variant="fullWidth"
          onChange={(event, newValue) => setActiveTab(newValue)}
          aria-label="customer profile tabs"
        >
          <Tab label="Basic Profile" />
          <Tab label="Purchase History" />
        </Tabs>
      </Box>

      {/* Tab Content */}
      {activeTab === 0 && (
        <Card
          sx={{
            backgroundColor: colors.primary[400],
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            padding: "20px",
            margin: "40px 0"
          }}
        >
          <CardContent>
            <Typography variant="h3" sx={{ marginBottom: "36px", fontWeight: "bold" }}>
              Profile Information
            </Typography>

            <Box>
              {[
                { label: "Full Name", value: customer.fullName },
                { label: "Email", value: customer.email },
                { label: "Phone Number", value: customer.phoneNumber },
                { label: "Store Address", value: customer.storeAddress },
                { label: "City", value: customer.city },
              ].map((item, index) => (
                <Grid
                  container
                  key={index}
                  spacing={2}
                  alignItems="center"
                  sx={{ marginBottom: "20px" }}
                >
                  <Grid item xs={6}>
                    <Typography variant="body1" fontSize="1rem" fontWeight="bold">
                      {item.label}:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography variant="body1" fontSize="1rem">
                      {item.value || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </CardContent>
        </Card>
      )}

      {activeTab === 1 && (
        <Box sx={{ margin: "40px 0" }}>
          <Typography variant="h4" gutterBottom fontWeight="bold" mb="40px">
            Purchase History
          </Typography>
          <DataGrid
            rows={sales}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            loading={loading}
            getRowId={(row) => row.id}
          />
        </Box>
      )}

    </Box>
  );
};

export default CustomerProfile;
