import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { login } from "../../services/UserService";
import { TextField, Button, Container, Typography, Box, Alert, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const initialValues = {
    username: "",
    password: ""
};

const userSchema = yup.object().shape({
    username: yup.string().required("This field is required!"),
    password: yup.string().required("This field is required!"),
});

const LoginPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();

    const handleFormSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            const response = await login(values.username, values.password);
            // debugger
            console.log(response);
            

            // Check if the response contains a token
            if (response.data.token) {
                // Check the message to determine the next action
                if (response.message && response.message.includes("change your password")) {
                    // Redirect to the password reset page
                    navigate('/password-reset');
                } else {
                    // Proceed to the dashboard if no password reset is needed
                    navigate('/dashboard');
                }
            } else {
                setErrors({ username: response.message || "Login failed" });
            }
        } catch (error) {
            setErrors({ username: error.response?.data?.message || error.message });
            setTimeout(() => {
                setErrors({});
            }, 5000);
        } finally {
            setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: userSchema,
        onSubmit: handleFormSubmit 
    });

    return (
        <Container
            maxWidth="xs"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "80vh",
            }}
        >
            <Box sx={{
                minWidth: "400px",
                maxWidth: "640px",
                width: "100%",
                padding: "24px",
                borderRadius: "8px",
                backgroundColor: colors.primary[400],
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
            }}>
                <Typography variant="h2" textAlign="center" fontWeight="bold" marginBottom="16px">
                    Login
                </Typography>
                {formik.errors.username &&
                <Alert 
                    severity="error" 
                    sx={{ 
                        backgroundColor: colors.redAccent[700],
                        fontSize: "0.7rem",
                        mb: "10px"
                    }}
                >
                    {formik.errors.username}
                </Alert>}
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="username"
                        type="text"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        error={formik.touched.username && Boolean(formik.errors.username)}
                        helperText={formik.touched.username && formik.errors.username}
                        InputProps={{
                            sx: { fontSize: "0.8rem" }  // Increases font size for the input text
                        }}
                        InputLabelProps={{
                            sx: { fontSize: "0.8rem" }  // Increases font size for the label
                        }}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="password"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        InputProps={{
                            sx: { fontSize: "0.8rem" }  // Increases font size for the input text
                        }}
                        InputLabelProps={{
                            sx: { fontSize: "0.8rem" }  // Increases font size for the label
                        }}
                    />
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary" 
                        fullWidth
                        sx={{
                            marginTop: "16px",
                            padding: "10px 0",
                            fontSize: "0.8rem"
                        }} >
                        Login
                    </Button>
                </form>
            </Box>
        </Container>
    
        
    );
};

export default LoginPage;
