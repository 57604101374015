import React from "react";
import { Dialog, DialogTitle, DialogContent, Grid, Typography, Box } from "@mui/material";

const ViewMoreDialog = ({ open, onClose, rowData }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth >

      <Box>
        <DialogTitle textAlign="center">
         <Typography variant="h4">Customer Details</Typography> 
        </DialogTitle>
      </Box>
      <DialogContent sx={{ paddingTop: "24px" }}>
        <Grid container spacing={2}>
          {[
            { label: "Sales ID", value: rowData.id },
            // { label: "Product Name", value: rowData.productName }, // Uncomment if needed
            { label: "Product Type", value: rowData.type },
            { label: "Quantity", value: rowData.quantity },
            { label: "Rate", value: rowData.rate },
            { label: "Sales Description", value: rowData.salesDesc },
            { label: "Customer Name", value: rowData.customerName },
            { label: "Customer Email", value: rowData.customerEmail },
            { label: "Date", value: rowData.date },
            { label: "Total Balance", value: rowData.totalBalance },
            { label: "Payment Status", value: rowData.paymentStatus },
            { label: "Outstanding Balance", value: rowData.outstandingBalance },
            { label: "Installment Plan", value: rowData.installmentPlan },
          ].map((item, index) => (
            <Grid container key={index} spacing={2} sx={{ marginBottom: "12px", padding: "0 12px" }}>
              {/* Label */}
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold" fontSize="1rem">
                  {item.label}:
                </Typography>
              </Grid>

              {/* Value */}
              <Grid item xs={6} textAlign="right">
                <Typography variant="body1" fontSize="1rem">
                  {item.value || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ViewMoreDialog;
