import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Avatar,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
} from "@mui/material";
import { getYourProfile, isAdmin, updateUser } from "../../services/UserService";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";

const UserProfile = () => {
  const [profileInfo, setProfileInfo] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editableProfile, setEditableProfile] = useState({});
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  const fetchProfileInfo = async () => {
    try {
      const response = await getYourProfile();
      setProfileInfo(response.data);
      
    } catch (error) {
      console.error("Error fetching profile information:", error);
    }
  };

  const handleEditClick = () => {
    setEditableProfile({ ...profileInfo });
    setIsDialogOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableProfile({ ...editableProfile, [name]: value });
  };

  const handleSave = async () => {
    try {
      await updateUser(profileInfo.id, editableProfile);
      setProfileInfo(editableProfile);
      setIsDialogOpen(false);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  if (!profileInfo) return <Typography>Loading...</Typography>;

  return (
    <Box sx={{ padding: 3, width: "100%", mt: 5 }}>
      <Card
        sx={{
          borderRadius: 2,
          boxShadow: 3,
          background: colors.primary[400],
          padding: "20px",
        }}
      >
        <CardContent>
          {/* Header Section */}
          <Box display="flex" alignItems="center" mb={3}>
            <Avatar
              sx={{
                bgcolor: colors.blueAccent[500],
                width: 100,
                height: 100,
                fontSize: "2rem"
              }}
            >
              {profileInfo.fullName ? profileInfo.fullName.charAt(0) : "U"}
            </Avatar>
            <Box sx={{ ml: 2 }}>
              <Typography variant="h2" sx={{ color: theme.palette.neutral.main }}>
                {profileInfo.fullName || "N/A"}
              </Typography>
              <Typography variant="h5" sx={{ color: theme.palette.neutral.light, mt: 2 }}>
                {profileInfo.role || "N/A"}
              </Typography>
            </Box>
          </Box>

          {/* Profile Details */}
          <Grid container spacing={2}>
            {[
              { label: "Full Name", value: profileInfo.fullName || "N/A" },
              { label: "Email", value: profileInfo.email || "N/A" },
              { label: "Location", value: profileInfo.location || "N/A" },
              { label: "Username", value: profileInfo.username || "N/A" },
            ].map((item, index) => (
              <Grid item xs={12} key={index}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1" fontWeight="bold" sx={{ fontSize: "1rem" }}>
                    {item.label}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "1rem" }}>{item.value}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

          {isAdmin() && (
            <Button
                variant="contained"
                color="primary"
                onClick={handleEditClick}
                sx={{ mt: 8, padding: "8px 24px", fontSize: ".9rem" }}
            >
                Edit Profile
            </Button>
            )}

        </CardContent>
      </Card>

      {/* Edit Dialog */}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle textAlign="center" sx={{ fontSize: "1.25rem"}}>Edit Profile</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Full Name"
                name="fullName"
                value={editableProfile.fullName || ""}
                onChange={handleInputChange}
                margin="normal"
                inputProps={{ style: { fontSize: "1rem" } }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={editableProfile.email || ""}
                onChange={handleInputChange}
                margin="normal"
                inputProps={{ style: { fontSize: "1rem" } }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Location"
                name="location"
                value={editableProfile.location || ""}
                onChange={handleInputChange}
                margin="normal"
                inputProps={{ style: { fontSize: "1rem" } }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Username"
                name="username"
                value={editableProfile.username || ""}
                onChange={handleInputChange}
                margin="normal"
                inputProps={{ style: { fontSize: "1rem" } }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ margin: "0 16px 10px 0"}}>
          <Button onClick={() => setIsDialogOpen(false)} color="error" sx={{ fontSize: ".9rem"}}>
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary" sx={{ fontSize: ".9rem"}}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserProfile;
