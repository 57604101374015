import axios from "axios";
import { jwtDecode } from "jwt-decode";

const REST_API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const getAuthToken = () => localStorage.getItem("token");

export const login = async (username, password) => {
    try {
        const response = await axios.post(`${REST_API_BASE_URL}/auth/login`, {
            username,
            password,
        });
        // debugger
        console.log(response.data.token);

        // Store the token and role in local storage upon successful login
        localStorage.setItem("token", response.data.token);
        console.log("Token from localStorage:", localStorage.getItem("token"));

        localStorage.setItem("role", response.data.role);

        return response;
    } catch (error) {
        throw error; // Handle error appropriately in your UI
    }
};

export const changePassword = (
    currentPassword,
    newPassword,
    confirmPassword,
) => {
    const token = getAuthToken();
    return axios.post(
        `${REST_API_BASE_URL}/auth/change-password`,
        { currentPassword, newPassword, confirmPassword }, // Include confirmPassword in the request body
        {
            headers: { Authorization: `Bearer ${token}` },
        },
    );
};

export const createUser = (user) =>
    axios.post(`${REST_API_BASE_URL}/auth/register`, user, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
    });

export const getAllUsers = () =>
    axios.get(`${REST_API_BASE_URL}/admin/get-all-users`, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
    });

export const getYourProfile = () =>
    axios.get(`${REST_API_BASE_URL}/adminuser/get-info`, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
    });

export const getUser = (userId) =>
    axios.get(`${REST_API_BASE_URL}/admin/get-users/${userId}`, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
    });

export const deleteUser = (userId) =>
    axios.delete(`${REST_API_BASE_URL}/admin/delete/${userId}`, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
    });

export const updateUser = (userId, user) =>
    axios.put(`${REST_API_BASE_URL}/admin/update/${userId}`, user, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
    });

// Updated logout function to notify backend
export const logout = async () => {
    const token = getAuthToken();
    try {
        await axios.post(
            `${REST_API_BASE_URL}/auth/logout`,
            {},
            {
                headers: { Authorization: `Bearer ${token}` },
            },
        );
    } catch (error) {
        console.error("Logout failed", error); // Handle logout error
    } finally {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
    }
};

export const isAuthenticated = () => {
    const token = getAuthToken();
    if (!token || token.split(".").length !== 3) return false; // No token means not authenticated

    // Check for token expiration
    try {
        const { exp } = jwtDecode(token); // Decode the JWT token
        const isExpired = Date.now() >= exp * 1000; // Convert exp to milliseconds
        if (isExpired) {
            logout(); // Log the user out if the token is expired
            return false; // Not authenticated
        }
    } catch (error) {
        console.error("Token decoding failed", error);
        logout();
        return false; // In case of an error, assume not authenticated
    }

    return true; // Token is valid
};

export const isAdmin = () => {
    const role = localStorage.getItem("role");
    return role === "ADMIN";
};

export const isUser = () => {
    const role = localStorage.getItem("role");
    return role === "USER";
};

export const adminOnly = () => isAuthenticated() && isAdmin();
