import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import {
  createCustomer,
  updateCustomer,
} from "../../services/CustomerService";

// TODO: Validation Schema

const CustomerForm = ({
  open,
  handleClose,
  onCustomerAdded,
  onCustomerEdited,
  editCustomer,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const editMode = Boolean(editCustomer);

  const initialValues = {
    fullName: editCustomer?.fullName || "",
    phoneNumber: editCustomer?.phoneNumber || "",
    email: editCustomer?.email || "",
    storeAddress: editCustomer?.storeAddress || "",
    city: editCustomer?.city || "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (editMode) {
        const response = await updateCustomer(editCustomer.id, values);
        onCustomerEdited(response.data);
      } else {
        const response = await createCustomer(values);
        onCustomerAdded(response.data);
      }
      handleClose();
    } catch (error) {
      console.error(
        `Failed to ${editMode ? "update" : "create"} customer details: `,
        error
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle textAlign="center" variant="h3" margin="10px 0">
        {editMode ? "Edit Customer Details" : "Add New Customer"}
      </DialogTitle>
      <DialogContent>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="20px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                gridColumn="span 4"
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Full Name"
                  name="fullName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fullName}
                  error={!!touched.fullName && !!errors.fullName}
                  helperText={touched.fullName && errors.fullName}
                  sx={{ gridColumn: "span 2" }}
                  InputLabelProps={{
                    style: {
                      marginTop: "4px",
                    },
                  }}
                  InputProps={{
                    style: {
                      padding: "8px 12px",
                    },
                  }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Phone Number"
                  name="phoneNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phoneNumber}
                  error={!!touched.phoneNumber && !!errors.phoneNumber}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{ min: 0 }}
                  InputLabelProps={{
                    style: {
                      marginTop: "4px",
                    },
                  }}
                  InputProps={{
                    style: {
                      padding: "8px 0",
                      alignItems: "center",
                    },
                  }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Store Address"
                  name="storeAddress"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.storeAddress}
                  error={!!touched.storeAddress && !!errors.storeAddress}
                  helperText={touched.storeAddress && errors.storeAddress}
                  sx={{ gridColumn: "span 4" }}
                  InputLabelProps={{
                    style: {
                      marginTop: "4px",
                    },
                  }}
                  InputProps={{
                    style: {
                      padding: "8px 0",
                    },
                  }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="email"
                  label="Email"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2" }}
                  InputLabelProps={{
                    style: {
                      marginTop: "4px",
                    },
                  }}
                  InputProps={{
                    style: {
                      padding: "8px 0",
                    },
                  }}
                />


                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="City"
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.city}
                  error={!!touched.city && !!errors.city}
                  helperText={touched.city && errors.city}
                  sx={{ gridColumn: "span 2" }}
                  InputLabelProps={{
                    style: {
                      marginTop: "4px",
                    },
                  }}
                  InputProps={{
                    style: {
                      padding: "8px 0",
                    },
                  }}
                />
              </Box>
              <DialogActions sx={{ mt: "20px", float: "right" }}>
                <Button
                  onClick={handleClose}
                  sx={{ color: colors.redAccent[400], padding: "8px 12px" }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  sx={{
                    color: colors.gray[100],
                    backgroundColor: colors.blueAccent[700],
                    padding: "12px 16px",
                  }}
                >
                  {editMode ? "Update" : "Submit"}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CustomerForm;
