import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Typography, TextField, Button, Alert, useTheme, Container } from '@mui/material';
import { tokens } from '../../theme';
import { useNavigate } from 'react-router-dom';
import { changePassword } from '../../services/UserService';

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

// Validation schema
const validationSchema = yup.object().shape({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .required('New password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

const PasswordResetForm = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleFormSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const { currentPassword, newPassword, confirmPassword } = values;

      // Call changePassword service with all three parameters
      await changePassword(currentPassword, newPassword, confirmPassword);

      // Redirect to login or dashboard after successful password change
      navigate('/dashboard');
    } catch (error) {
      setErrors({ currentPassword: error.message });
      setTimeout(() => setErrors({}), 5000); // Clear errors after 5 seconds
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <Container
      maxWidth="xs"
      sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "80vh",
      }}
    >
      <Box
      sx={{
        minWidth: '500px',
        maxWidth: "800px",
        width: "100%",
        padding: "32px",
        borderRadius: "8px",
        backgroundColor: colors.primary[400],
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
      }}
      >
        <Typography variant="h3" textAlign="center" fontWeight="bold">
          Change Password
        </Typography>

        {formik.errors.currentPassword && (
          <Alert severity="error">{formik.errors.currentPassword}</Alert>
        )}

        <form onSubmit={formik.handleSubmit}>
          <TextField
            label="Current Password"
            variant="outlined"
            fullWidth
            margin="normal"
            name="currentPassword"
            type="password"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
            helperText={formik.touched.currentPassword && formik.errors.currentPassword}
          />

          <TextField
            label="New Password"
            variant="outlined"
            fullWidth
            margin="normal"
            name="newPassword"
            type="password"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />

          <TextField
            label="Confirm New Password"
            variant="outlined"
            fullWidth
            margin="normal"
            name="confirmPassword"
            type="password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          />

          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            fullWidth
            sx={{
              marginTop: "20px",
              padding: "12px 0",
              fontSize: "0.8rem"
            }} 
          >
            Change Password
          </Button>
        </form>
      </Box>
    </Container>
    
  );
};

export default PasswordResetForm;
