import { Box } from "@mui/material";
import { useState, useEffect } from "react"; 
import { Navigate } from "react-router-dom";
import { isAuthenticated, isAdmin, isUser, logout } from "../../services/UserService"; 
import Sidebar from "../../pages/global/Sidebar";
import Topbar from "../../pages/global/Topbar";
import { jwtDecode } from "jwt-decode"; 

const PrivateRoute = ({ children, requiredRole }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const authenticated = isAuthenticated();


  // Token expiration check
  const token = localStorage.getItem('token');
  if (token) {
    const { exp } = jwtDecode(token);
    const isExpired = Date.now() >= exp * 1000;
    if (isExpired) {
      logout(); // Log out if token is expired
      return <Navigate to="/login" replace />;
    }
  }

  // Redirect to login if not authenticated
  if (!authenticated) {
    return <Navigate to="/login" replace />;
  }

  // Redirect to dashboard if role is not sufficient
  if (requiredRole && (requiredRole === 'ADMIN' && !isAdmin()) || (requiredRole === 'USER' && !isUser())) {
    return <Navigate to="/dashboard" replace />;
  }

  const handleCollapseToggle = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };
 

  return (
    <Box display="flex" className="app">
      <Box
        // width={isSidebarCollapsed ? "80px" : "280px"}
        sx={{
          transition: "width 0.3s ease-in-out",
          overflow: "hidden",
        }}
      >
        <Sidebar isCollapsed={isSidebarCollapsed} onCollapseToggle={handleCollapseToggle} />
      </Box>

      <Box 
        flexGrow={1} 
        overflow="auto" 
        sx={{ 
          // marginLeft: isSidebarCollapsed ? "80px" : "280px",
          transition: "all 0.3s ease-in-out" 
        }}
      >
        <Topbar />
        {children}
      </Box>
    </Box>
  );
};

export default PrivateRoute;
