import axios from "axios";

const REST_API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/sales`;

const TOTAL_BALANCE_SUM_URL = `${process.env.REACT_APP_BACKEND_URL}/api/sales/total-balance`;

const TOTAL_BALANCE_SUM_BY_MONTH_URL = (month, year) =>
    `${TOTAL_BALANCE_SUM_URL}/${month}/${year}`;

export const listSalesRecord = () =>
    axios.get(REST_API_BASE_URL, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });

export const createSalesRecord = (sales) =>
    axios.post(REST_API_BASE_URL, sales, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Roles: localStorage.getItem("role"),
        },
    });

export const getTotalBalanceSum = () =>
    axios.get(TOTAL_BALANCE_SUM_URL, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });

export const getTotalMonthlySales = (month, year) =>
    axios.get(TOTAL_BALANCE_SUM_BY_MONTH_URL(month, year), {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });

export const updateSalesRecord = (salesId, sales) =>
    axios.put(`${REST_API_BASE_URL}/${salesId}`, sales, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });

export const getSalesRecord = (salesId) =>
    axios.get(`${REST_API_BASE_URL}/${salesId}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });

export const deleteSalesRecord = (salesId) =>
    axios.delete(`${REST_API_BASE_URL}/${salesId}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });

export const deleteAllSalesRecords = () =>
    axios.delete(`${REST_API_BASE_URL}/deleteAll`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });

export const getRecentTransactions = (page, size) => {
    return axios.get(`${REST_API_BASE_URL}/recent`, {
        params: { page, size },
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });
};

export const getSalesByCustomerId = (customerId, page, size) => {
    return axios.get(`${REST_API_BASE_URL}/customer/${customerId}`, {
        params: { page, size },
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    });
};

export const getOwingCustomers = () => { };
