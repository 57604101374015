import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { createProduct, updateProduct } from "../../services/ProductService";
import { Formik } from "formik";
import { useState } from "react";

const ProductForm = ({
    open,
    handleClose,
    onProductAdded,
    onProductEdited,
    editProduct,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const editMode = Boolean(editProduct);
    const [categories, setCategories] = useState(["Cement", "Building Blocks"]);
    const [newCategoryDialogOpen, setNewCategoryDialogOpen] = useState(false);
    const [newCategory, setNewCategory] = useState("");

    const initialValues = {
        productName: editProduct?.productName || "",
        productDesc: editProduct?.productDesc || "",
        category: editProduct?.category || "",
        sourceType: editProduct?.sourceType || "",
        supplierName: editProduct?.supplierName || "",
        cost: editProduct?.cost || "",
        quantity: editProduct?.quantity || "",
    };

    const handleAddCategory = () => {
        if (newCategory.trim() && !categories.includes(newCategory.trim())) {
          setCategories([...categories, newCategory.trim()]);
        }
        setNewCategory("");
        setNewCategoryDialogOpen(false);
      };
    

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
          if (editMode) {
            const response = await updateProduct(editProduct.id, values);
            onProductEdited(response.data);
          } else {
            const response = await createProduct(values);
            onProductAdded(response.data);
          }
          handleClose();
        } catch (error) {
          console.error(
            `Failed to ${editMode ? "update" : "create"} product: `,
            error
          );
        } finally {
          setSubmitting(false);
        }
      };

      return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle textAlign="center" variant="h3" margin="10px 0">
                    {editMode ? "Edit Product" : "Add New Product"}
                </DialogTitle>
                <DialogContent>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        handleSubmit,
                    }) => (
                        <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="20px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            gridColumn="span 4"
                        >
                            <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Product Name"
                            name="productName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.productName}
                            error={!!touched.productName && !!errors.productName}
                            helperText={touched.productName && errors.productName}
                            sx={{ gridColumn: "span 2" }}
                            inputlabelprops={{
                                style: {
                                marginTop: "4px",
                                },
                            }}
                            InputProps={{
                                style: {
                                padding: "8px 0",
                                },
                            }}
                            />

                            <Select
                                fullWidth
                                variant="filled"
                                label="Category"
                                name="category"
                                value={values.category}
                                onChange={(event) => setFieldValue("category", event.target.value)}
                                onBlur={handleBlur}
                                error={!!touched.category && !!errors.category}
                                displayEmpty
                                sx={{
                                gridColumn: "span 2",
                                ".MuiSelect-select": {
                                    paddingTop: "12px",
                                    paddingBottom: "12px",
                                },
                                }}
                            >
                                <MenuItem value="" disabled>
                                Select Category
                                </MenuItem>
                                {categories.map((category) => (
                                <MenuItem key={category} value={category}>
                                    {category}
                                </MenuItem>
                                ))}
                                <MenuItem value="add-category" style={{ color: colors.blueAccent[300] }} onClick={() => setNewCategoryDialogOpen(true)}>
                                Add New Category
                                </MenuItem>
                            </Select>

                            <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Product Description"
                            name="productDesc"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.productDesc}
                            error={!!touched.productDesc && !!errors.productDesc}
                            helperText={touched.productDesc && errors.productDesc}
                            sx={{ gridColumn: "span 4" }}
                            inputProps={{ min: 0 }}
                            inputlabelprops={{
                                style: {
                                marginTop: "4px",
                                },
                            }}
                            InputProps={{
                                style: {
                                padding: "8px 0",
                                alignItems: "center",
                                },
                            }}
                            />

                            <Select
                                fullWidth
                                variant="filled"
                                label="Source"
                                name="sourceType"
                                value={values.sourceType}
                                onChange={(event) => setFieldValue("sourceType", event.target.value)}
                                onBlur={handleBlur}
                                error={!!touched.sourceType && !!errors.sourceType}
                                displayEmpty
                                sx={{ 
                                    gridColumn: "span 2",
                                    '.MuiSelect-select': {
                                        paddingTop: "12px",
                                        paddingBottom: "12px",
                                    }
                                }}
                                inputlabelprops={{
                                    style: {
                                        marginTop: "4px",
                                    },
                                }}    
                            >
                                <MenuItem value="" disabled>
                                    Select Source
                                </MenuItem>
                                <MenuItem value="Manufactured">Manufactured</MenuItem>
                                <MenuItem value="Purchased">Purchased</MenuItem>
                            </Select>

                            <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Supplier"
                            name="supplierName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.supplierName}
                            error={!!touched.supplierName && !!errors.supplierName}
                            helperText={touched.supplierName && errors.supplierName}
                            sx={{ gridColumn: "span 2" }}
                            inputProps={{ min: 0 }}
                            inputlabelprops={{
                                style: {
                                marginTop: "4px",
                                },
                            }}
                            InputProps={{
                                style: {
                                padding: "10px 0",
                                },
                            }}
                            />

                            <TextField
                            fullWidth
                            variant="filled"
                            type="number"
                            label="Cost"
                            name="cost"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.cost}
                            error={!!touched.cost && !!errors.cost}
                            helperText={touched.cost && errors.cost}
                            sx={{ gridColumn: "span 2" }}
                            inputProps={{ min: 0 }}
                            inputlabelprops={{
                                style: {
                                marginTop: "4px",
                                },
                            }}
                            InputProps={{
                                style: {
                                padding: "10px 0",
                                },
                            }}
                            />

                            <TextField
                            fullWidth
                            variant="filled"
                            type="number"
                            label="Quantity"
                            name="quantity"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.quantity}
                            error={!!touched.quantity && !!errors.quantity}
                            helperText={touched.quantity && errors.quantity}
                            sx={{ gridColumn: "span 2" }}
                            inputProps={{ min: 0 }}
                            inputlabelprops={{
                                style: {
                                marginTop: "4px",
                                },
                            }}
                            InputProps={{
                                style: {
                                padding: "10px 0",
                                },
                            }}
                            />
                        </Box>
                        <DialogActions sx={{ mt: "20px", float: "right" }}>
                            <Button
                            onClick={handleClose}
                            sx={{ color: colors.redAccent[400], padding: "8px 12px" }}
                            >
                            Cancel
                            </Button>
                            <Button
                            type="submit"
                            sx={{
                                color: colors.gray[100],
                                backgroundColor: colors.blueAccent[700],
                                padding: "12px 16px",
                            }}
                            >
                            {editMode ? "Update" : "Submit"}
                            </Button>
                        </DialogActions>
                        </form>
                    )}
                    </Formik>
                </DialogContent>
            </Dialog>

                    {/* Dialog for Adding New Category */}
            <Dialog open={newCategoryDialogOpen} onClose={() => setNewCategoryDialogOpen(false)}>
                <DialogTitle>Add New Category</DialogTitle>
                <DialogContent>
                <TextField
                    fullWidth
                    variant="filled"
                    label="New Category"
                    value={newCategory}
                    onChange={(e) => setNewCategory(e.target.value)}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setNewCategoryDialogOpen(false)} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleAddCategory} color="primary">
                    Add
                </Button>
                </DialogActions>
            </Dialog>
        </>
        
      );
}

export default ProductForm;