import { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { getCementCurrentStock } from '../services/CementService';
import { getBlock1CurrentStock } from '../services/Block1Service';
import { getBlock2CurrentStock } from '../services/Block2Service';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../theme';


const Bar = ({ isDashboard = false }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [data, setData] = useState([]);

    useEffect(() => {
        // Fetch data for all categories
        Promise.all([
        getCementCurrentStock(),
        getBlock1CurrentStock(),
        getBlock2CurrentStock()
        ]).then(([cementRes, block1Res, block2Res]) => {

        // Prepare the data for Nivo chart
        const formattedData = [
            {
            category: "Cement",
            stock: cementRes.data,  // Adjust the key if necessary
            },
            {
            category: "Block1",
            stock: block1Res.data,
            },
            {
            category: "Block2",
            stock: block2Res.data,
            }
        ];
        setData(formattedData);
        }).catch((error) => {
        console.error("Error fetching stock data:", error);
        });
    }, []);

    const getColor = (stock) => {
        if (stock <= 20) return colors.redAccent[600];
        if (stock > 20 && stock <= 100) return '#a4a400';
        return colors.greenAccent[600];
    };

    return (
<ResponsiveBar
                data={data}
                theme={{
                    axis: {
                      domain: {
                        line: {
                          stroke: colors.gray[100],
                        },
                      },
                      legend: {
                        text: {
                          fill: colors.gray[100],
                        },
                      },
                      ticks: {
                        line: {
                          stroke: colors.gray[100],
                          strokeWidth: 1,
                        },
                        text: {
                          fill: colors.gray[100],
                        },
                      },
                    },
                    legends: {
                      text: {
                        fill: colors.gray[100],
                      },
                    },
                    tooltip: {
                      container: {
                        color: colors.primary[500],
                      },
                    },
                  }}
                keys={['stock']}
                indexBy="category"
                margin={{ top: 50, right: 130, bottom: 130, left: 90 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={d => getColor(d.data.stock)} // Set color based on stock value
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]]
                }}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: '#38bcb2',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: '#eed312',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                fill={[
                    {
                        match: {
                            id: 'Cement'
                        },
                        id: 'dots'
                    },
                    {
                        match: {
                            id: 'Block1'
                        },
                        id: 'lines'
                    }
                ]}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: isDashboard ? undefined : 'Product Category',
                    legendPosition: 'middle',
                    legendOffset: 45,
                    truncateTickAt: 0
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: isDashboard ? undefined : 'Stock Level',
                    legendPosition: 'middle',
                    legendOffset: -65,
                    truncateTickAt: 0,
                }}
                enableLabel={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]]
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role="application"
                ariaLabel="Stock bar chart"
                barAriaLabel={e => `${e.id}: ${e.formattedValue} in category: ${e.indexValue}`}
            />
        
    );
};

export default Bar;
