import axios from "axios";

const REST_API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/search`;

const token = localStorage.getItem("token");

export const searchRecords = (query, limit = 10) => {
    return axios.get(REST_API_BASE_URL, {
        params: {
            query: query,
            limit: limit,
        },
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });
};
