import { Box, Button, useTheme, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React, { useEffect, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Header from "../../components/Header";
import {
  listSalesRecord,
  getTotalBalanceSum,
  getSalesRecord,
  deleteSalesRecord,
  deleteAllSalesRecords,
} from "../../services/SaleService";
import SalesRecordForm from "../../components/Sales/SalesRecordForm";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SalesDeleteDialog from "../../components/Sales/SalesDeleteDialog";
// import { useGridApiContext } from "@mui/x-data-grid";
// import { GridFilterModel } from "@mui/x-data-grid";
import { isAdmin } from "../../services/UserService";
import ViewMoreDialog from "../../components/Sales/ViewMoreDialog";

const Sales = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  // const [ filteredRows, setFilteredRows ] = useState([]);
  const [open, setOpen] = useState(false);
  const [totalBalanceSum, setTotalBalanceSum] = useState(0);
  const [editRecord, setEditRecord] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  // const [ isFiltered, setIsFiltered ] = useState(false);
  const [openViewMore, setOpenViewMore] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const admin = isAdmin();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditRecord(null);
  };

  const handleOpenDialog = (id = null) => {
    setDeleteRecord(id);
    setIsBulkDelete(id === null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleViewMore = (rowData) => {
    setSelectedRowData(rowData);
    setOpenViewMore(true);
  };

  const handleCloseViewMore = () => {
    setOpenViewMore(false);
    setSelectedRowData(null);
  };

  useEffect(() => {
    listSalesRecord()
      .then((response) => {
        const data = response.data.map((item) => ({
          id: item.id,
          ...item,
        }));
        setRows(data);

        getTotalBalanceSum()
          .then((response) => {
            setTotalBalanceSum(response.data);
          })
          .catch((error) => {
            console.error("Error fetching total balance sum: ", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  // useEffect(() => {
  //   console.log('Filtered Rows changed:', filteredRows);
  //   console.log('Is Filtered:', isFiltered);

  //   if (isFiltered) {
  //     const filteredTotalBalance = filteredRows.reduce(
  //       (sum, row) => sum + row.totalBalance,
  //       0
  //     );
  //     console.log('Setting Filtered Total Balance:', filteredTotalBalance); 
  //     setTotalBalanceSum(filteredTotalBalance);
  //   } else {
  //     getTotalBalanceSum().then((response) => {
  //       console.log('Total Balance from API:', response.data);
  //       setTotalBalanceSum(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching total balance sum: ", error);
        
  //     })
  //   }
  // }, [filteredRows, isFiltered]);

  const handleAddRecord = (newSalesRecord) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows, newSalesRecord];

      getTotalBalanceSum()
        .then((response) => {
          setTotalBalanceSum(response.data);
        })
        .catch((error) => {
          console.error("Error fetching total balance sum: ", error);
        });
      return updatedRows;
    });
  };

  const handleEditRecord = (row) => {
    getSalesRecord(row.id)
      .then((response) => {
        setEditRecord(response.data);
        setOpen(true);
      })
      .catch((error) => {
        console.error("Error fetching record by ID: ", error);
      });
  };

  const handleDeleteRecord = () => {
    if (isBulkDelete) {
      deleteAllSalesRecords()
        .then(() => {
          setRows([]);
          setTotalBalanceSum(0);
          setOpenDialog(false);
        })
        .catch((error) => {
          console.error("Error deleting all records: ", error);
        });
    } else if (deleteRecord) {
      deleteSalesRecord(deleteRecord)
        .then(() => {
          setRows((prevRows) =>
            prevRows.filter((row) => row.id !== deleteRecord)
          );
          getTotalBalanceSum()
            .then((response) => {
              setTotalBalanceSum(response.data);
            })
            .catch((error) => {
              console.error("Error fetching total balance sum: ", error);
            });
          setDeleteRecord(null);
          setOpenDialog(false);
        })
        .catch((error) => {
          console.error("Error deleting record: ", error);
        });
    }
  };

  const handleRecordEdited = (updatedRecord) => {
    setRows((prevRows) => {
      const updatedRows = prevRows.map((record) =>
        record.id === updatedRecord.id
          ? { ...record, ...updatedRecord }
          : record
      );

      getTotalBalanceSum()
        .then((response) => {
          setTotalBalanceSum(response.data);
        })
        .catch((error) => {
          console.error("Error fetching total balance sum: ", error);
        });

      console.log(updatedRecord);
      return updatedRows;
    });
  };

  // const handleFilterChange = (filterModel) => {
  //   console.log('Filter Model:', filterModel);

  //   const hasActiveFilters = filterModel.items.some((item) => item.value);

  //   const filteredData = rows.filter((row) => {
  //     return filterModel.items.every((item) => {
  //       const columnField = item.field;
  //       const columnValue = row[columnField];
  //       console.log(`Filtering column: ${columnField}, Value: ${columnValue}`); 

  //       return columnValue !== undefined && columnValue !== null && 
  //       columnValue.toString().toLowerCase().includes(item.value.toLowerCase());
  //     });
  //   });

  //   console.log("Filtered Rows: ", filteredData);
  //   console.log('Has Active Filters:', hasActiveFilters);
    
  //   setFilteredRows(filteredData);
  //   setIsFiltered(hasActiveFilters);

  //   const filteredTotalBalance = filteredData.reduce(
  //     (sum, row) => sum + row.totalBalance,
  //     0
  //   );

  //   console.log("Filtered Total Balance: ", filteredTotalBalance);
    
  //   setTotalBalanceSum(filteredTotalBalance);
  // };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.25 },
    // { field: "productName", headerName: "Product Name", flex: 0.5 },
    { field: "type", headerName: "Type", flex: 0.5 },
    { field: "quantity", headerName: "Quantity", flex: 0.5, valueFormatter: (params) => params.toLocaleString() },
    { field: "enteredBy", headerName: "Entered By", flex: 0.5},
    { field: "rate", headerName: "Rate", flex: 0.5, valueFormatter: (params) =>
        new Intl.NumberFormat("en-NG", {
          style: "currency",
          currency: "NGN",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0, 
        }).format(params)
    },
    { field: "salesDesc", headerName: "Description", flex: 1 },
    { field: "customerName", headerName: "Customer Name", flex: 0.5},
    { field: "customerEmail", headerName: "Customer Email", flex: 0.75 },
    { field: "date", headerName: "Date", flex: 0.5 },
    { field: "totalBalance", headerName: "Total Balance", flex: 0.75,
      valueFormatter: (params) =>
        new Intl.NumberFormat("en-NG", {
          style: "currency",
          currency: "NGN",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0, 
        }).format(params)
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      flex: 1.75,
      renderCell: (params) => (
        <Box display="flex" justifyContent="space-evenly" margin="10px 0">
          
          {/* View More Button - Accessible to all roles */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: colors.blueAccent[600],
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: colors.blueAccent[400],
                color: colors.blueAccent[300],
              },
            }}
            onClick={() => handleViewMore(params.row)}
          >
            <VisibilityOutlinedIcon sx={{ mr: "8px" }} /> View More
          </Button>
  
          {/* Edit and Delete Buttons - Accessible to admin only */}
          {admin && (
            <>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colors.greenAccent[600],
                  "&:hover": {
                    backgroundColor: "transparent",
                    borderColor: colors.greenAccent[400],
                    color: colors.greenAccent[300],
                  },
                }}
                onClick={() => handleEditRecord(params.row)}
              >
                <EditOutlinedIcon sx={{ mr: "8px" }} /> Edit
              </Button>
  
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colors.redAccent[600],
                  "&:hover": {
                    backgroundColor: "transparent",
                    borderColor: colors.redAccent[400],
                    color: colors.redAccent[300],
                  },
                }}
                onClick={() => handleOpenDialog(params.row.id)}
              >
                <DeleteOutlinedIcon sx={{ mr: "8px" }} /> Delete
              </Button>
            </>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box>
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.gray[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            margin: "10px 0",
            float: "right",
            "&:hover": {
              backgroundColor: colors.blueAccent[800],
            },
          }}
          onClick={handleClickOpen}
        >
          <AddOutlinedIcon sx={{ mr: "10px" }} />
          Add Record
        </Button>
          {admin && (
                    <Button
                    sx={{
                      backgroundColor: colors.redAccent[600],
                      color: colors.gray[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                      margin: "10px 20px",
                      float: "right",
                      "&:hover": {
                        backgroundColor: colors.redAccent[700],
                      },
                    }}
                    onClick={() => handleOpenDialog(null)}
                  >
                    <DeleteOutlinedIcon sx={{ mr: "10px" }} />
                    Delete All Records
                  </Button>
          )}

        
        <SalesRecordForm
          open={open}
          handleClose={handleClose}
          onRecordAdded={handleAddRecord}
          editRecord={editRecord}
          onRecordEdited={handleRecordEdited}
        />
      </Box>
      <Header title="SALES" subtitle="Sales transactions listed here" />
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },

          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },

          "& .MuiDataGrid-columnHeader": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },

          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },

          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },

          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[100]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.gray[100]} !important`,
            margin: "0 20px 20px 0",
            fontSize: "14px"
          },
        }}
      >
        <DataGrid 
          rows={rows}
          columns={columns}
          // autoHeight
          slots={{ toolbar: GridToolbar }}
          // onFilterModelChange={handleFilterChange}
        />
      </Box>

      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h5">Total Sales Balance: </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          ₦{totalBalanceSum.toLocaleString()}
        </Typography>
      </Box>

      <SalesDeleteDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleDeleteRecord}
        isBulkDelete={isBulkDelete}
      />

    {selectedRowData && (
      <ViewMoreDialog
        open={openViewMore}
        onClose={handleCloseViewMore}
        rowData={selectedRowData} // Pass the row data to the dialog
      />
    )}
    </Box>
  );
};

export default Sales;
