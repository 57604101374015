import { Box } from "@mui/material";
import UserProfile from "../../components/User/UserProfile"; // Adjust the path if needed
import Header from "../../components/Header";

const Profile = () => {
    return (
        <Box sx={{
            margin: "10px 15px"
        }}>
            <Header title="USER PROFILE" subtitle="View your profile information here" />
            <UserProfile />
        </Box>
    );
};

export default Profile;
