import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import {
  createSalesRecord,
  updateSalesRecord,
} from "../../services/SaleService";
import { listProducts } from "../../services/ProductService";
import CustomerForm from "../Customer/CustomerForm";
import { listCustomer } from "../../services/CustomerService";

// TODO: Validation Schema

const SalesRecordForm = ({
  open,
  handleClose,
  onRecordAdded,
  onRecordEdited,
  editRecord,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const editMode = Boolean(editRecord);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await listProducts();
      setProducts(response.data);
    };

    const fetchCustomers = async () => {
      // Fetch customers to populate the customer dropdown
      const response = await listCustomer(); // Assuming you have a getCustomers API
      setCustomers(response.data);
    };

    fetchProducts();
    fetchCustomers();
  }, []);

  const handleCloseCustomerModal = () => {
    setOpenCustomerModal(false);
  };
  
  const handleCustomerAdded = (newCustomer) => {
    setCustomers([...customers, newCustomer]);
    setSelectedCustomerId(newCustomer.id);
    setOpenCustomerModal(false);
  };

  const initialValues = {
    type: editRecord?.type || "",
    quantity: editRecord?.quantity || "",
    rate: editRecord?.rate || "",
    salesDesc: editRecord?.salesDesc || "",
    customerName: editRecord?.customerName || "",
    customerEmail: editRecord?.customerEmail || "",
    date: editRecord?.date || "",
    productId: editRecord?.productId || "",
    paymentStatus: editRecord?.paymentStatus || "PENDING_PAYMENT",
    outstandingBalance: editRecord?.outstandingBalance || "",
    installmentPlan: editRecord?.installmentPlan || "INACTIVE",
    customerId: selectedCustomerId || (editRecord ? editRecord.customerId : null),
    
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const salesData = {
        ...values,
        productId: selectedProduct ? selectedProduct.id : null,
        paymentStatus: values.paymentStatus,
        outstandingBalance: values.outstandingBalance,
        installmentPlan: values.installmentPlan,
        customerId: selectedCustomerId,
      };

      console.log("Submitting sales data:", salesData);
      
      if (editMode) {
        const response = await updateSalesRecord(editRecord.id, salesData);
        onRecordEdited(response.data);
      } else {
        const response = await createSalesRecord(salesData);
        onRecordAdded(response.data);
      }
      handleClose();
    } catch (error) {
      console.error(
        `Failed to ${editMode ? "update" : "create"} sales record: `,
        error
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle textAlign="center" variant="h3" margin="10px 0">
          {editMode ? "Edit Sales Record" : "Add New Sales Record"}
        </DialogTitle>
        <DialogContent>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  gridColumn="span 4"
                >
                  <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", height: "68px" }}>
                    <InputLabel id="product-select-label">Product</InputLabel>
                    <Select
                      labelId="product-select-label"
                      value={selectedProduct ? selectedProduct.id : ""}
                      sx={{
                        height: "100%", // Ensure it takes the full height of the FormControl
                        display: 'flex', // Align vertically
                        alignItems: 'center', // Center align vertically
                      }}
                      onChange={(event) => {
                        const product = products.find((p) => p.id === event.target.value);
                        setSelectedProduct(product);
                        setFieldValue('rate', product ? product.cost : "");
                        setFieldValue('type', product ? product.category : "");
                      }}
                      onBlur={handleBlur}
                    >
                      {products.map((product) => (
                        <MenuItem key={product.id} value={product.id}>
                          {product.productName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Type"
                    name="type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.type}
                    error={!!touched.type && !!errors.type}
                    helperText={touched.type && errors.type}
                    sx={{ gridColumn: "span 2" }}
                    InputLabelProps={{
                      style: {
                        marginTop: "4px",
                      },
                    }}
                    InputProps={{
                      style: {
                        padding: "8px 12px",
                      },
                    }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    min="0"
                    label="Quantity"
                    name="quantity"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.quantity}
                    error={!!touched.quantity && !!errors.quantity}
                    helperText={touched.quantity && errors.quantity}
                    sx={{ gridColumn: "span 2" }}
                    inputProps={{ min: 0 }}
                    InputLabelProps={{
                      style: {
                        marginTop: "4px",
                      },
                    }}
                    InputProps={{
                      style: {
                        padding: "8px 0",
                        alignItems: "center",
                      },
                    }}
                  />

                  <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", height: "68px" }}>
                    <InputLabel id="customer-select-label">Customer Name</InputLabel>
                    <Select
                      labelId="customer-select-label"
                      value={values.customerName}
                      sx={{
                        height: "100%",
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onChange={(event) => {
                        const selectedValue = event.target.value;
                        if (selectedValue === "add-new") {
                          setOpenCustomerModal(true);
                        } else {
                          const selectedCustomer = customers.find((customer) => customer.fullName === selectedValue);
                          setFieldValue("customerName", selectedCustomer ? selectedCustomer.fullName : "");
                          setFieldValue("customerEmail", selectedCustomer ? selectedCustomer.email : "");
                          setSelectedCustomerId(selectedCustomer ? selectedCustomer.id : null); // Set customerId dynamically
                        }
                      }}
                      onBlur={handleBlur}
                    >
                      {customers.map((customer) => (
                        <MenuItem key={customer.id} value={customer.fullName}>
                          {customer.fullName}
                        </MenuItem>
                      ))}
                      <MenuItem value="add-new" style={{ color: colors.blueAccent[300] }}>
                        Add New Customer
                      </MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Description"
                    name="salesDesc"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.salesDesc}
                    error={!!touched.salesDesc && !!errors.salesDesc}
                    helperText={touched.salesDesc && errors.salesDesc}
                    sx={{ gridColumn: "span 4" }}
                    InputLabelProps={{
                      style: {
                        marginTop: "4px",
                      },
                    }}
                    InputProps={{
                      style: {
                        padding: "8px 0",
                      },
                    }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="email"
                    label="Customer Email"
                    name="customerEmail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerEmail}
                    error={!!touched.customerEmail && !!errors.customerEmail}
                    helperText={touched.customerEmail && errors.customerEmail}
                    sx={{ gridColumn: "span 2" }}
                    InputLabelProps={{
                      style: {
                        marginTop: "4px",
                      },
                    }}
                    InputProps={{
                      style: {
                        padding: "8px 0",
                      },
                    }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Rate"
                    name="rate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.rate}
                    error={!!touched.rate && !!errors.rate}
                    helperText={touched.rate && errors.rate}
                    sx={{ gridColumn: "span 2" }}
                    inputProps={{ min: 0 }}
                    InputLabelProps={{
                      style: {
                        marginTop: "4px",
                      },
                    }}
                    InputProps={{
                      style: {
                        padding: "10px 0",
                      },
                    }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="date"
                    label="Date"
                    name="date"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.date}
                    error={!!touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                    sx={{
                      gridColumn: "span 2",
                    }}
                    InputProps={{
                      style: {
                        padding: "10px 0",
                      },
                    }}
                  />

                  <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", height: "68px" }}>
                    <InputLabel id="payment-status-label">Payment Status</InputLabel>
                    <Select
                      labelId="payment-status-label"
                      name="paymentStatus"
                      value={values.paymentStatus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{
                        height: "100%", // Ensure it takes the full height of the FormControl
                        display: 'flex', // Align vertically
                        alignItems: 'center', // Center align vertically
                      }}
                    >
                      <MenuItem value="FULLY_PAID">Fully Paid</MenuItem>
                      <MenuItem value="PARTIAL_PAYMENT">Partial Payment (Installment)</MenuItem>
                      <MenuItem value="PENDING_PAYMENT">Pending Payment</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Outstanding Balance"
                    name="outstandingBalance"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.outstandingBalance}
                    error={!!touched.outstandingBalance && !!errors.outstandingBalance}
                    helperText={touched.outstandingBalance && errors.outstandingBalance}
                    sx={{ gridColumn: "span 2" }}
                    InputLabelProps={{
                      style: { marginTop: "4px" },
                    }}
                    InputProps={{
                      style: { padding: "8px 0" },
                    }}
                  />

                <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", height: "68px" }}>
                  <InputLabel id="installment-plan-label">Installment Plan</InputLabel>
                  <Select
                    labelId="installment-plan-label"
                    name="installmentPlan"
                    value={values.installmentPlan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                      height: "100%", // Ensure it takes the full height of the FormControl
                      display: 'flex', // Align vertically
                      alignItems: 'center', // Center align vertically
                    }}
                  >
                    <MenuItem value="INACTIVE">Inactive</MenuItem>
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="COMPLETED">Completed</MenuItem>
                  </Select>
                </FormControl>


                </Box>
                <DialogActions sx={{ mt: "20px", float: "right" }}>
                  <Button
                    onClick={handleClose}
                    sx={{ color: colors.redAccent[400], padding: "8px 12px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    sx={{
                      color: colors.gray[100],
                      backgroundColor: colors.blueAccent[700],
                      padding: "12px 16px",
                    }}
                  >
                    {editMode ? "Update" : "Submit"}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <CustomerForm
        open={openCustomerModal}
        handleClose={handleCloseCustomerModal}
        onCustomerAdded={handleCustomerAdded}
      />
    </>
    
    
  );
};

export default SalesRecordForm;
