import { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { getTotalMonthlySales } from '../services/SaleService';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../theme';

const LineChart = ({ isDashboard = false }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);

    useEffect(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // October = 9 (0-indexed)
        const year = currentDate.getFullYear();

        // Create an array with the months starting from October and continuing
        const months = [];
        for (let i = currentMonth; i < currentMonth + 12; i++) {
            const month = i % 12 + 1; // Wrap around to 1 after December (12)
            months.push({ month, year: i >= 12 ? year + 1 : year });
        }

        // Fetch data for each month dynamically
        const promises = months.map(({ month, year }) => getTotalMonthlySales(month, year));

        Promise.all(promises).then((responses) => {
            const formattedData = responses.map((res, index) => ({
                x: `${months[index].month}/${months[index].year}`,
                y: res.data, // Adjust this based on your API response structure
            }));
            setData([{ id: "Total Sales", data: formattedData }]);
        }).catch((error) => {
            console.error("Error fetching sales data:", error);
        });
    }, []);

    return (
      <ResponsiveLine
      data={data}
      theme={{
          axis: {
              domain: {
                  line: { stroke: colors.gray[100] },
              },
              legend: {
                  text: { fill: colors.gray[100] },
              },
              ticks: {
                  line: {
                      stroke: colors.gray[100],
                      strokeWidth: 1,
                  },
                  text: {
                      fill: colors.gray[100],
                  },
              },
          },
          legends: { text: { fill: colors.gray[100] } },
          tooltip: { container: { color: colors.primary[500] } },
      }}
      margin={{ top: 50, right: 130, bottom: 50, left: 90 }}
      xScale={{ type: 'point' }}
      yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false,
      }}
      axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: isDashboard ? undefined : 'Month',
          legendOffset: 36,
          legendPosition: 'middle',
      }}
      axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickValues: 5,
          tickPadding: 5,
          // tickRotation: 0,?  
          legend: isDashboard ? undefined : 'Total Sales',
          legendOffset: -80,
          legendPosition: 'middle',
      }}
      // colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }}
      curve='catmullRom'
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
          {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                  {
                      on: 'hover',
                      style: { itemOpacity: 1 },
                  },
              ],
          },
      ]}
      gridXValues={[]}
      gridYValues={[]}
  />
    );
};

export default LineChart;
