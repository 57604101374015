import { Box, IconButton, useTheme, Menu, MenuItem, Avatar, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Autocomplete } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { getYourProfile, isAdmin, isAuthenticated, logout } from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import { searchRecords } from "../../services/SearchService";

const Topbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [query, setQuery] = useState(""); // Store the search query
    const [results, setResults] = useState([]); // Store the search results
    const [loading, setLoading] = useState(false); // Loading state

    const navigate = useNavigate();
    
    const [user, setUser] = useState({ fullName: "", role: "" });
    const authenticated = isAuthenticated();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await getYourProfile();
                if (response && response.data) {
                    setUser({
                        fullName: response.data.fullName,
                        role: response.data.role
                    });
                }
            } catch (error) {
                console.error("Failed to fetch user info:", error);
            }
        };

        if (authenticated) {
            fetchProfile();
        }
    }, [authenticated]);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    }

    const openLogoutDialog = () => {
        setIsDialogOpen(true);
    };

    const closeLogoutDialog = () => {
        setIsDialogOpen(false);
    };

    const handleConfirmLogout = () => {
            logout(); // Ensure this function clears the token and user state
            handleProfileMenuClose();
            navigate("/login");
    }

    const handleUserProfile = () => {
        handleProfileMenuClose();
        navigate("/profile");
    }

    const handleSearch = async (event) => {
        const searchQuery = event.target.value;
        console.log(searchQuery);
        
        setQuery(searchQuery);

        if (!searchQuery.trim()) {
            setResults([]); // Clear results if the query is empty
            return;
        }

        setLoading(true); // Show loading state

        try {
            const response = await searchRecords(searchQuery, 10, 'relevance'); // API call
            setResults(response.data.categories || []); // Update results
        } catch (error) {
            console.error('Error fetching search results:', error);
            setResults([]);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* SEARCH BAR WITH AUTOCOMPLETE */}
            <Box
                display="flex" 
                backgroundColor={colors.primary[400]} 
                borderRadius="3px"
                sx={{
                    transform: "scale(0.8)", // Scale down to 80%
                    transformOrigin: "top left", // Set the origin for scaling
                  }}
            >
                <Autocomplete
                    freeSolo
                    value={query}
                    onInputChange={handleSearch} // Trigger API call on input change
                    options={results.flatMap(category => category.results || [])} // Flatten the results array
                    getOptionLabel={(option) => option.customerName || option.fullName || option.description || ''}
                    renderInput={(params) => (
                        <InputBase
                            {...params}
                            sx={{ ml: 2, flex: 1, mt: 0.5 }}
                            placeholder="Search"
                        />
                    )}
                    loading={loading} // Show loading spinner while fetching data
                    renderOption={(props, option) => (
                        <li {...props}>
                            {option.customerName || option.fullName || option.description}
                        </li>
                    )}
                />
                <IconButton type="button" sx={{ p: 1 }}>
                    <SearchIcon />
                </IconButton>
            </Box>

            {/* ICONS */}
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === 'dark' ? (
                        <DarkModeOutlinedIcon sx={{ fontSize: "20px", mr: "8px"}}/> 
                    ) : (
                        <LightModeOutlinedIcon sx={{ fontSize: "20px", mr: "8px"}}/>
                    )} 
                    <Typography variant="h6">Appearance</Typography>
                </IconButton>
                {/* <IconButton>
                    <NotificationsOutlinedIcon />
                </IconButton>
                <IconButton>
                    <SettingsOutlinedIcon />
                </IconButton> */}
                {authenticated && (
                    <IconButton onClick={handleProfileMenuOpen} sx={{ ml: "16px"}}>
                        <PersonOutlinedIcon sx={{ fontSize: "20px", mr: "8px"}}/> 
                        <Typography variant="h6">Profile</Typography>
                    </IconButton>
                )}
            </Box>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleProfileMenuClose}
            >
                <MenuItem>
                    <Avatar>{user.fullName.charAt(0)}</Avatar>
                    <Box sx={{ ml: 2 }}>
                        <Typography variant="body1">{user.fullName}</Typography>
                        <Typography variant="body2" marginTop="5px" color={colors.greenAccent[600]}>
                            {user.role}
                        </Typography>
                    </Box>
                </MenuItem>
                {authenticated && 
                <MenuItem onClick={handleUserProfile}>
                    <PersonOutlinedIcon sx={{ mr: "10px"}}/> User Profile
                </MenuItem>}
                {authenticated && 
                <MenuItem onClick={openLogoutDialog}>
                    <LogoutOutlinedIcon sx={{ mr: "10px"}}/> Log out
                </MenuItem>}
            </Menu>

            <Dialog
                open={isDialogOpen}
                onClose={closeLogoutDialog}
                aria-labelledby="logout-dialog-title"
                aria-describedby="logout-dialog-description"
            >
                <DialogTitle id="logout-dialog-title" textAlign="center">
                  <Typography fontWeight="bold">Confirm Logout</Typography>  
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="logout-dialog-description">
                        Are you sure you want to log out?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeLogoutDialog} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleConfirmLogout();
                            closeLogoutDialog();
                        }}
                        color="secondary"
                    >
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Topbar;
