import axios from "axios";

const REST_API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/expenses`;

const TOTAL_BALANCE_SUM_URL = `${process.env.REACT_APP_BACKEND_URL}/api/expenses/total-balance`;

const TOTAL_BALANCE_SUM_BY_MONTH_URL = (month, year) =>
    `${TOTAL_BALANCE_SUM_URL}/${month}/${year}`;

const token = localStorage.getItem("token");

export const listExpensesRecord = () =>
    axios.get(REST_API_BASE_URL, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

export const createExpensesRecord = (expenses) =>
    axios.post(REST_API_BASE_URL, expenses, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

export const getTotalBalanceSum = () =>
    axios.get(TOTAL_BALANCE_SUM_URL, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

export const getTotalMonthlyExpenses = (month, year) =>
    axios.get(TOTAL_BALANCE_SUM_BY_MONTH_URL(month, year), {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

export const updateExpensesRecord = (expensesId, expenses) =>
    axios.put(`${REST_API_BASE_URL}/${expensesId}`, expenses, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

export const getExpensesRecord = (expensesId) =>
    axios.get(`${REST_API_BASE_URL}/${expensesId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

export const deleteExpensesRecord = (expensesId) =>
    axios.delete(`${REST_API_BASE_URL}/${expensesId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

export const deleteAllExpensesRecords = () =>
    axios.delete(`${REST_API_BASE_URL}/deleteAll`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

