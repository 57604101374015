import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import { createExpensesRecord, updateExpensesRecord } from "../../services/ExpenseService";
import { listProducts } from "../../services/ProductService";

// TODO: Validation Schema

const ExpensesRecordForm = ({ open, handleClose, onRecordAdded, onRecordEdited, editRecord }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const editMode = Boolean(editRecord);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
          const response = await listProducts();
          setProducts(response.data);
        };
        fetchProducts();
      }, []);

    const initialValues = {
        type: editRecord?.type || "",
        quantity: editRecord?.quantity || "",
        cost: editRecord?.cost || "",
        expensesDesc: editRecord?.expensesDesc || "",
        supplierName: editRecord?.supplierName || "",
        date: editRecord?.date || "",
        productId: editRecord?.productId || "",
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const expensesData = {
                ...values,
                productId: selectedProduct ? selectedProduct.id : null,
              };

              console.log("Submitting expenses data:", expensesData);

            if (editMode) {
                const response = await updateExpensesRecord(editRecord.id, expensesData);
                onRecordEdited(response.data)
            }
            else{
                const response = await createExpensesRecord(expensesData);
                onRecordAdded(response.data);
            }
            handleClose();
        } catch (error) {
            console.error(`Failed to ${editMode ? "update" : "create"} expenses record: `, error);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth = "md"
            fullWidth
        >
                <DialogTitle textAlign="center" variant="h3" margin="10px 0">
                    {editMode ? "Edit Expenses Record" : "Add New Expenses Record"}</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({values, errors, touched, handleBlur, handleChange, setFieldValue, handleSubmit}) => (
                            <form onSubmit={handleSubmit}>
                                <Box
                                    display="grid"
                                    gap="20px"
                                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                    gridColumn="span 4">

                                        <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", height: "68px" }}>
                                    <InputLabel id="product-select-label">Product</InputLabel>
                                    <Select
                                        labelId="product-select-label"
                                        value={selectedProduct ? selectedProduct.id : ""}
                                        sx={{
                                        height: "100%", // Ensure it takes the full height of the FormControl
                                        display: 'flex', // Align vertically
                                        alignItems: 'center', // Center align vertically
                                        }}
                                        onChange={(event) => {
                                        const product = products.find((p) => p.id === event.target.value);
                                        setSelectedProduct(product);
                                    
                                        if (product) {
                                            setFieldValue('cost', product.cost);
                                            setFieldValue('type', product.category);
                                        } else {
                                            setFieldValue('rate', "");
                                            setFieldValue('type', "");
                                        }
                                        }}
                                        onBlur={handleBlur}
                                    >
                                        {products.map((product) => (
                                        <MenuItem key={product.id} value={product.id}>
                                            {product.productName}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                    
                                    <Select
                                        fullWidth
                                        variant="filled"
                                        label="Type"
                                        name="type"
                                        value={values.type}
                                        onChange={(event) => setFieldValue("type", event.target.value)}
                                        onBlur={handleBlur}
                                        error={!!touched.type && !!errors.type}
                                        displayEmpty
                                        sx={{ 
                                            gridColumn: "span 2",
                                            '.MuiSelect-select': {
                                                paddingTop: "12px",
                                                paddingBottom: "12px",
                                            }
                                        }}
                                        inputlabelprops={{
                                            style: {
                                                marginTop: "4px",
                                            },
                                        }}    
                                    >
                                        <MenuItem value="" disabled>
                                            Select Type
                                        </MenuItem>
                                        <MenuItem value="Cement">Cement</MenuItem>
                                        <MenuItem value="Building Blocks">Building Blocks</MenuItem>
                                    </Select>

                                    <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Description"
                                            name="expensesDesc"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.expensesDesc}
                                            error={!!touched.expensesDesc && !!errors.expensesDesc}
                                            helperText={touched.expensesDesc && errors.expensesDesc}
                                            sx={{ gridColumn: "span 4"}}
                                            inputlabelprops={{
                                                style: { 
                                                    marginTop: '4px',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    padding: "8px 0"
                                                }
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="number"
                                            min="0"
                                            label="Quantity"
                                            name="quantity"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.quantity}
                                            error={!!touched.quantity && !!errors.quantity}
                                            helperText={touched.quantity && errors.quantity}
                                            sx={{ gridColumn: "span 2"}}
                                            inputProps={{ min: 0 }}
                                            inputlabelprops={{
                                                style: { 
                                                    marginTop: '4px',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    padding: "8px 0",
                                                    alignItems: "center"
                                                }
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Supplier"
                                            name="supplierName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.supplierName}
                                            error={!!touched.supplierName && !!errors.supplierName}
                                            helperText={touched.supplierName && errors.supplierName}
                                            sx={{ gridColumn: "span 2"}}
                                            inputlabelprops={{
                                                style: { 
                                                    marginTop: '4px',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    padding: "8px 0"
                                                }
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="number"
                                            label="Cost"
                                            name="cost"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.cost}
                                            error={!!touched.cost && !!errors.cost}
                                            helperText={touched.cost && errors.cost}
                                            sx={{ gridColumn: "span 2"}}
                                            inputProps={{ min: 0 }}
                                            inputlabelprops={{
                                                style: { 
                                                    marginTop: '4px',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    padding: "10px 0"
                                                }
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="date"
                                            label="Date"
                                            name="date"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.date}
                                            error={!!touched.date && !!errors.date}
                                            helperText={touched.date && errors.date}
                                            sx={{
                                                 gridColumn: "span 2",
                                            }}
                                            InputProps={{
                                                style: {
                                                    padding: "10px 0"
                                                }
                                            }}
                                        />
                                    </Box>
                                    <DialogActions sx={{mt: "20px", float: "right"}}>
                                        <Button onClick={handleClose} sx={{color: colors.redAccent[400], padding: "8px 12px"}}>Cancel</Button>
                                        <Button type="submit" sx={{color: colors.gray[100], backgroundColor: colors.blueAccent[700], padding: "12px 16px"}}>{editMode ? "Update" : "Submit"}</Button>
                                    </DialogActions>
                            </form>
                        )}
                    </Formik>
                </DialogContent>  
            </Dialog>
    )
}

export default ExpensesRecordForm;